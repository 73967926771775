import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Container from './globals/container'
import CategoryCard from './category-card'

import patternTop from '../images/pattern-05.png'
import patternBottom from '../images/pattern-03.png'

const Categories = () => {
  const { categories } = useStaticQuery(graphql`
    query {
      categories: allContentfulCategory(sort: { order: ASC, fields: order }) {
        nodes {
          name
          slug
          backgroundImage {
            description
            gatsbyImageData(width: 360, height: 270)
          }
          description {
            childMdx {
              body
            }
          }
        }
      }
    }
  `)
  return (
    <Wrapper>
      <Container>
        <Title>Ejes del Proyecto</Title>
        <Grid>
          {categories.nodes.map(category => (
            <CategoryCard key={category.slug} {...category} />
          ))}
        </Grid>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  background-color: #4559b3;
  padding-top: 120px;
  padding-bottom: 140px;
  margin-top: 60px;
  margin-bottom: 60px;

  ${props => props.theme.mq.desktop} {
    padding-top: 180px;
    padding-bottom: 180px;
    margin-top: 120px;
    margin-bottom: 120px;
  }

  &:before {
    height: 71px;
    top: 0;
    background: url(${patternTop});
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &:after {
    height: 75px;
    bottom: 0;
    z-index: 2;
    background: url(${patternBottom}) no-repeat;
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

const Title = styled.h2`
  text-align: center;
  margin-bottom: 40px;
  color: #fff;

  ${props => props.theme.mq.tabletLandscape} {
    margin-bottom: 60px;
  }
`

const Grid = styled.div`
  position: relative;
  z-index: 1;

  > * {
    & + * {
      margin-top: 20px;
    }
  }

  ${props => props.theme.mq.tablet} {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;

    > * {
      width: calc(50% - 16px);
      margin: 0 8px;

      &:nth-child(n + 3) {
        margin-top: 16px;
      }
    }
  }

  ${props => props.theme.mq.desktop} {
    margin: 0 -15px;

    > * {
      width: calc(25% - 30px);
      margin: 0 15px;
      &:nth-child(n + 3) {
        margin-top: 0;
      }
    }
  }
`

export default Categories
