import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const CategoryCard = ({ backgroundImage, name, description, slug }) => {
  return (
    <Card to={`/category/${slug}`}>
      <ImageContainer>
        <GatsbyImage
          alt={backgroundImage.description}
          image={backgroundImage.gatsbyImageData}
        />
      </ImageContainer>
      <ContentContainer>
        <div>
          <Title>{name}</Title>
          <Description>
            <MDXRenderer>{description.childMdx.body}</MDXRenderer>
          </Description>
        </div>
        <Cta>
          Ver artículos
          <FontAwesomeIcon icon={faAngleRight} />
        </Cta>
      </ContentContainer>
    </Card>
  )
}

const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  text-decoration: none;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  }
`

const ImageContainer = styled.div`
  position: relative;

  .gatsby-image-wrapper {
    position: relative;
    width: 100%;
    height: 270px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 30px 20px 20px;

  & > div {
    flex-grow: 1;
  }

  ${props => props.theme.mq.wide} {
    padding: 40px;
  }
`

const Title = styled.h3`
  font-size: 26px;
  line-height: 28px;
  margin-bottom: 0;
`

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #666;
`

const Cta = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: ${props => props.theme.colors.eduRed};
  margin-top: 30px;

  svg {
    margin-left: 5px;
    transform: translateY(1px);
  }
`

export default CategoryCard
