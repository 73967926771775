import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import Container from './globals/container'

const AboutUs = () => (
  <Wrapper>
    <Inner>
      <ImageContainer>
        <ImageClip>
          <StaticImage
            alt="Niña leyendo un libro"
            src="../images/teaching.jpeg"
            width={621}
          />
        </ImageClip>
        <FlyingIconImage>
          <StaticImage
            alt="Decoration"
            src="../images/flying-icons/flying-icon3.png"
            placeholder="none"
          />
        </FlyingIconImage>
      </ImageContainer>
      <ContentContainer>
        <h2>Bienvenidos a EduKapulí</h2>
        <p>
          En la educación todos aprendemos: familias, educadores y niños.
          Bienvenidos a <strong>EduKapulí</strong>, esta es una iniciativa para
          difundir ideas, artículos, curiosidades, experiencias, opiniones,
          noticias, proyectos e innovaciones en temas de Educación Infantil.
          Cuatro serán los ejes de este proyecto de difusión educativa:
        </p>
        <List>
          <Item>
            <Link to="/literatura">Literatura</Link>
          </Item>
          <Item>
            <Link to="/ciencia-arte-y-tecnologia">
              Ciencia, Arte y Tecnología
            </Link>
          </Item>
          <Item>
            <Link to="/temas-de-interes">Temas de interés</Link>
          </Item>
          <Item>
            <Link to="/innovacion-educativa">Innovación Educativa</Link>
          </Item>
        </List>
      </ContentContainer>
      <FlyingIconRightTop>
        <StaticImage
          alt="Decoration"
          src="../images/flying-icons/flying-icon1.png"
          placeholder="none"
        />
      </FlyingIconRightTop>
      <FlyingIconRightBottom>
        <StaticImage
          alt="Decoration"
          src="../images/flying-icons/flying-icon4.png"
          placeholder="none"
        />
      </FlyingIconRightBottom>
    </Inner>
  </Wrapper>
)

const Wrapper = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;

  ${props => props.theme.mq.tablet} {
    margin-top: 100px;
    margin-bottom: 100px;
  }
`

const Inner = styled(Container)`
  position: relative;

  ${props => props.theme.mq.tabletLandscape} {
    display: flex;
    align-items: center;
  }
`

const ImageContainer = styled.div`
  position: relative;
  margin: 0 auto 40px;
  max-width: 600px;

  ${props => props.theme.mq.tabletLandscape} {
    flex-shrink: 0;
    width: 40%;
    margin: 0 40px 0 0;
    max-width: none;
  }

  ${props => props.theme.mq.desktop} {
    margin-right: 80px;
  }
`

const ImageClip = styled.div`
  .gatsby-image-wrapper {
    border-radius: 20px;
    clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
    height: 0;
    padding-bottom: 100%;
  }
`

const ContentContainer = styled.div`
  ${props => props.theme.mq.desktop} {
    max-width: 45%;
  }
`

const List = styled.ol`
  list-style: none;
  margin: 40px 0 0;
  padding: 0;
`

const Item = styled.li`
  counter-increment: counter-list;
  padding-left: 55px;
  position: relative;

  & + li {
    margin-top: 30px;
  }

  a {
    font-family: ${props => props.theme.seccondaryFont};
    font-size: 20px;
    color: ${props => props.theme.colors.primaryTextColor};
    font-weight: 700;
    text-decoration: none;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: ${props => props.theme.colors.eduRed};
    }
  }

  &:before {
    font-family: ${props => props.theme.seccondaryFont};
    content: counter(counter-list);
    position: absolute;
    z-index: 9;
    color: #fff;
    top: 4px;
    left: 16px;
    font-weight: 700;
    font-size: 18px;
  }

  &:after {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.eduYellow};
    box-shadow: inset 0px -4px 0px 0px rgb(0 0 0 / 10%);
  }
`

const FlyingIconImage = styled.div`
  position: absolute;
  top: 10%;
  right: 10%;
`

const FlyingIconRightTop = styled.div`
  position: absolute;
  right: 35px;
  top: 10px;

  ${props => props.theme.mq.until.desktop} {
    display: none;
  }
`

const FlyingIconRightBottom = styled.div`
  position: absolute;
  right: 120px;
  bottom: 10px;

  ${props => props.theme.mq.until.tabletLandscape} {
    display: none;
  }
`

export default AboutUs
