import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled, { keyframes } from 'styled-components'

import Container from './globals/container'

import pattern from '../images/white-pattern.png'
import pattern2 from '../images/light-pattern.png'

// markup
const HomePageHero = () => {
  return (
    <Wrapper>
      <Inner>
        <FlyingIconLeft>
          <StaticImage
            alt="Decoration"
            src="../images/flying-icons/flying-icon1.png"
            placeholder="none"
          />
        </FlyingIconLeft>
        <FlyingIconCenter>
          <StaticImage
            alt="Decoration"
            src="../images/flying-icons/flying-icon8.png"
            placeholder="none"
          />
        </FlyingIconCenter>
        <FlyingIconRight>
          <StaticImage
            alt="Decoration"
            src="../images/flying-icons/flying-icon2.png"
            placeholder="none"
          />
        </FlyingIconRight>
        <Content>
          <h1>Educación Transformadora</h1>
          <Description>
            Reinventamos juntos la Educación. Ven y explora, aprende e Innova
          </Description>
        </Content>
        <ImageWrapper>
          <StaticImage alt="Decoration" src="../images/homepage-hero.jpeg" />
        </ImageWrapper>
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.background};
  padding-bottom: 50px;
  position: relative;

  ${props => props.theme.mq.tablet} {
    padding-top: 80px;
  }

  ${props => props.theme.mq.desktop} {
    padding-top: 90px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 13px;
    background-image: url(${pattern});
    z-index: 1;
  }
`

const Inner = styled(Container)`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;

  ${props => props.theme.mq.until.tablet} {
    padding: 0;
  }

  ${props => props.theme.mq.tablet} {
    flex-direction: row;
    align-items: center;
  }
`

const Content = styled(Container)`
  ${props => props.theme.mq.until.tablet} {
    padding: 0 20px;
  }
`

const moveAnimation = keyframes`
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(40px, 40px);
  }

  100% {
    transform: translate(0, 0);
  }
`

const growthAnimation = keyframes`
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
`

const FlyingIconLeft = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  animation: ${moveAnimation} 10s ease-in-out infinite;

  ${props => props.theme.mq.until.tabletLandscape} {
    display: none;
  }
`

const FlyingIconCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  animation: ${growthAnimation} 10s ease-in-out infinite;

  ${props => props.theme.mq.until.tabletLandscape} {
    display: none;
  }
`

const FlyingIconRight = styled.div`
  position: absolute;
  top: 0;
  right: 20px;
  animation: ${growthAnimation} 10s ease-in-out infinite;

  ${props => props.theme.mq.until.tabletLandscape} {
    display: none;
  }
`

const Description = styled.p`
  ${props => props.theme.mq.tabletLandscape} {
    font-size: 20px;
  }
`

const ImageWrapper = styled.div`
  margin: 0 auto 40px;
  width: 100%;

  ${props => props.theme.mq.tablet} {
    flex-shrink: 0;
    width: 40%;
    margin-left: 80px;
    margin-bottom: 0;
    max-width: none;
  }

  .gatsby-image-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;

    ${props => props.theme.mq.until.tablet} {
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 12%,
          rgba(255, 255, 255, 0) 40%
        );
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 13px;
        background-image: url(${pattern2});
      }
    }

    ${props => props.theme.mq.tablet} {
      border-radius: 50%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

export default HomePageHero
