import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/globals/layout'
import HomePageHero from '../components/homepage-hero'
import AboutUs from '../components/about-us'
import Categories from '../components/categories'
import FeaturedPosts from '../components/featured-posts'

// markup
const IndexPage = () => {
  const { posts } = useStaticQuery(graphql`
    query {
      posts: allContentfulBlogPost(
        sort: { fields: [publishDate], order: DESC }
        limit: 3
      ) {
        nodes {
          title
          slug
          publishDate(formatString: "MMMM, DD YYYY", locale: "es_EC")
          heroImage {
            description
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              width: 424
              height: 212
              quality: 100
            )
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <HomePageHero />
      <AboutUs />
      <Categories />
      <FeaturedPosts
        posts={posts.nodes}
        title="Artículos recientes"
        isHome={true}
      />
    </Layout>
  )
}

export default IndexPage
